<template>
  <div>
    <van-cell-group>
      <van-cell title="社区"
        :value="hzlist.OrgName" />
      <van-cell title="小区"
        :value="hzlist.RName" />
      <van-cell title="名称"
        :value="hzlist.Name" />
      <!-- <van-cell title="法定代表人" :value="hzlist.Legaler" /> -->
      <van-cell title="联系人"
        :value="hzlist.TouchMan" />
      <van-cell title="联系电话"
        :value="hzlist.TouchTel" />
      <!-- <van-cell title="成立日期" :value="hzlist.FoundDate | capitalizeTime" /> -->
      <!-- <van-cell title="类型" :value="hzlist.ProTypeName" /> -->
      <!-- <van-cell title="注册资本" :value="hzlist.RegCapital" /> -->
      <!-- <van-cell title="营业执照编号" :value="hzlist.LicCode" /> -->
      <van-cell title="地址"
        :value="hzlist.Address" />

      <van-cell title="备注"
        :value="hzlist.Remark" />
      <!-- <van-cell title="核酸检测次数"
        :value="hzlist.NACheckCount" /> -->
      <!-- <div style="width: 95%; margin: auto">
        <div v-if="hzlist.LicImg">
          <div style="margin: 0 5px 5px 7px; font-size: 15px; color: #323233">
            营业执照图片
          </div>
          <img
            style="width: 80px; height: 80px"
            :src="hzlist.LicImgUrl"
            @click="openHealth(hzlist.LicImgUrl)"
          />
        </div>
      </div> -->
    </van-cell-group>
    <div style="margin-bottom: 60px">
      <div style="margin: 0 5px 5px 15px; font-size: 15px; color: #323233">
        服务信息
      </div>
      <div class="warp">
        <div class="wktime">
          <div v-for="item in fuwulist"
            :key="item.ProSerId"
            class="listRid"
            @click="xiangqing(item)">
            <div class="listRidtitle">{{ item.SerTypeName }}</div>
            <div class="listRidname">
              <span>联系人: {{ item.TouchMan }}</span>
              <span>联系电话: {{ item.TouchTel }}</span>
            </div>
            <div class="listRidname">服务内容: {{ item.SerContent }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WxGetProperty, WxGetProServiceList } from "@/api/fangyi";
import { getwgToken } from "@/utils/auth";
import { ImagePreview } from "vant";
export default {
  name: "ImagePreview ",
  data () {
    return {
      listfrom: {},
      hzlist: [],
      url: "https://api.qxzhqm.cn/",
      HealthCodeUrl: [],
      fuwulist: [],
    };
  },
  created () {
    this.cyXq();
    this.fuwuList();
  },
  filters: {
    capitalizeTime: function (value) {
      if (!value) return "";
      let gstime = value.substring(0, 10);
      return gstime;
    },
  },
  methods: {
    // 健康码
    openHealth: function (row) {
      console.log(row);
      ImagePreview({
        // images:this.newImgUrl,
        images: [row],
        startPosition: 0,
        closeOnPopstate: true,
      });
    },
    // 行程码
    TravelHealth: function (row) {
      console.log(row);
      ImagePreview({
        // images:this.newImgUrl,
        images: [row],
        startPosition: 0,
        closeOnPopstate: true,
      });
    },
    // 核酸证明
    TestifyHealth: function (row) {
      console.log(row);
      ImagePreview({
        // images:this.newImgUrl,
        images: [row],
        startPosition: 0,
        closeOnPopstate: true,
      });
    },
    // 获取服务详情
    cyXq () {
      console.log(this.$route);
      WxGetProperty({ proId: this.$route.params.id })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.hzlist = {};
            } else {
              this.hzlist = res.data.data;
            }
          }
        })
        .catch(() => { });
    },
    // 服务信息列表
    fuwuList () {
      console.log(this.$route);
      WxGetProServiceList({ proId: this.$route.params.id })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.fuwulist = {};
            } else {
              this.fuwulist = res.data.data;
            }
          }
        })
        .catch(() => { });
    },
    xiangqing: function (row) {
      console.log(row);
      var itemmark = JSON.stringify(row);
      this.$router.push({
        path: "/wuye/wuyeList/wuyeXiangQing/fuwuXiangQing/" + row.ProSerId,
      });
    },
  },
};
</script>
<style scoped>
.van-cell {
  font-size: 15px !important;
}
.rightClass {
  width: 60% !important;
}
.wktime {
  padding: 0 20px;
}
.wktype {
  padding: 0 10px 10px;
}
.orginview .iconimg {
  width: 62%;
}
.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px;
}
.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: '';
}
.listRid {
  /* background: rgb(245, 242, 242); */
  border-radius: 15px;
  /* box-shadow: 1px 1px 1px #cececc; */
  border: 1px solid #cececc;
  margin: 8px 0px;
  padding: 8px;
  font-size: 14px;
}
.listRid .listRidtitle {
  border-bottom: 1px solid #999;
  padding-bottom: 8px;
  font-size: 16px;
}
.listRidname {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}
.listRidAddress {
  padding: 3px 0px;
}
</style>